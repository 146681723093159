import React, { Component } from "react";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
// import TrackVisitor from "./TrackVisitor";
// import TrackVisitorAndIP from "./TrackVisitorAndIP";

import EightHourComponent from "./EightHourComponent";
import Page404 from "./404Page";
import SignupTracker from "./Signup/SignupTracker";
import Comp from "./ReceiptUploader/Comp";
import ThankYou from "./ThankYou";
import ThankYou2 from "./Signup/ThankYou";
import EightHourHydraplay from "./EightHourHydraplay";
import Signups from "./Signup/EaSignups";
import Quiz from "./Quiz/Quiz";
import { LeaderboardUrlWrapper } from "./ActivationLeaderboard/LeaderboardUrl";
import Instruction from "./Ar/instruction";

class ExampleComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			urls: [
				// {
				// 	link: "comicconjar",
				// 	title: "Comic con 8 Hour sample QR ",
				// },
				{
					link: "presskit",
					title: "Comic con Press Kit QR",
				},
				{
					link: "comicconpinth",
					title: "Comic con plinth QR",
				},
				{
					link: "instabuynow",
					title: "Comic con instagram link",
				},
			],
		};
	}
	// comicconpinth
	render() {
		return (
			<Router>
				<Routes>
					{/* <Route path={`/`} element={<Page404 />} /> */}
					{this.state.urls.map((d, i) => (
						<Route
							path={`/${d.link}`}
							element={
								<EightHourComponent
									urlPath={"8 Hour comic con sample QR"}
								/>
							}
						/>
					))}

					<Route path='/hydraplay'>
						<Route
							path=''
							exact
							element={<EightHourHydraplay root={"hydraplay"} />}
						/>

						<Route
							path='quiz'
							exact
							element={<Quiz root={"hydraplay"} />}
						/>
						<Route
							path='signup'
							exact
							element={<Signups root={"hydraplay"} />}
						/>
						<Route
							path='thankyou'
							exact
							element={<ThankYou2 root={"hydraplay"} />}
						/>
						<Route
							path='leaderboard/:mall'
							exact
							element={
								<LeaderboardUrlWrapper root={"hydraplay"} />
							}
						/>
					</Route>

					<Route
						path='/signupform'
						exact
						element={<SignupTracker />}
					/>

					<Route path='/thankyou' exact element={<ThankYou />} />

					<Route
						path='/arinstruction'
						exact
						element={<Instruction />}
					/>

					<Route path='/entercomp' element={<Comp />} />

					{/* <Route
						path={`/instagram`}
						element={<EightHourComponent urlPath={"instagram"} />}
					/>

					<Route
						path={`/instabuynow`}
						element={
							<EightHourComponent
								urlPath={"comic con instagram"}
							/>
						}
					/>

					<Route
						path={`/presskit`}
						element={
							<EightHourComponent
								urlPath={"8 Hour presskit QR"}
							/>
						}
					/> */}
					<Route path='*' element={<Navigate to='/hydraplay' />} />
				</Routes>
			</Router>
		);
	}
}

export default ExampleComponent;
