import React, { Component } from "react";
import { View, Text } from "react-native";
import axios from "axios";
import { api_qrscanned } from "../Api";
import SignUp from "./Signup";
// import Comp from "./Comp";

function generateFormId() {
	let formId = "";
	const characters = "abcdef0123456789";
	for (let i = 0; i < 16; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		const randomCharacter = characters[randomIndex];
		if (i === 4 || i === 8 || i === 12) {
			formId += "-";
		}
		formId += randomCharacter;
	}
	return formId;
}

export default class Signups extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectUrl: null,
			isLoading: true,
			isMounted: false,
			analyticsData: null,
			enterCompetition: false,
		};
	}

	async componentDidMount() {
		if (!this.state.isMounted) {
			await this.trackInteractionS();
			await this.checkSignupData();
			await this.setState({ isMounted: true });
		}

		const analyticsData = JSON.parse(localStorage.getItem("analyticsData"));
		if (!analyticsData) {
			localStorage.setItem(
				"analyticsData",
				JSON.stringify(this.state.analyticsData),
			);
		}
	}

	trackInteractionS = async () => {
		const ip = await this.geoAppify1();

		let analyticsData = JSON.parse(localStorage.getItem("analyticsData"));

		if (!analyticsData) {
			console.log("geoAppify SET", ip);
			let form_id = generateFormId();
			analyticsData = {
				...ip,
				userAgent: window.navigator.userAgent,
				screenWidth: window.screen.width,
				screenHeight: window.screen.height,
				form_id: form_id,
			};
			localStorage.setItem(
				"analyticsData",
				JSON.stringify(analyticsData),
			);

			await fetch(api_qrscanned, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					allocation: "EA - WHITE TEA ACTIVATION - Signup QR",
					...analyticsData,
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					this.setState({
						redirectUrl: data.redirectUrl,
						isLoading: false,
					});
					return null;
				})
				.catch((error) => {
					console.error(error);
					this.setState({ isLoading: false });
				});
		}
	};

	geoAppify1 = async () => {
		try {
			const { data } = await axios.get(`https://ipapi.co/json/`);

			const analyticsData = {
				...data,
				userAgent: window.navigator.userAgent,
				screenWidth: window.screen.width,
				screenHeight: window.screen.height,
			};

			// console.log("geoAppify1", analyticsData);

			return analyticsData;
		} catch (error) {
			return error;
		}
	};

	checkSignupData = () => {
		const signupData = localStorage.getItem("signupData");
		if (signupData) {
			const data = JSON.parse(signupData);
			this.setState({
				enterCompetition: data.enterCompetition,
			});
		}
	};

	render() {
		return <SignUp />;
	}
}
