import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	Image,
	ScrollView,
	TouchableOpacity,
} from "react-native";
import axios from "axios";
import Fade from "react-reveal/Fade";
import { api_qrscanned } from "./Api";
import "./index.css";
import FollowUs from "./Signup/FollowUs";
import { TermsAndConditionsCheckBox } from "./ReceiptUploader/TermsAndConditions";

import "./Quiz/Quiz.css";

function generateFormId() {
	let formId = "";
	const characters = "abcdef0123456789";
	for (let i = 0; i < 16; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		const randomCharacter = characters[randomIndex];
		if (i === 4 || i === 8 || i === 12) {
			formId += "-";
		}
		formId += randomCharacter;
	}
	return formId;
}

export default class EightHourHydraplay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			route: this.props.urlPath,
			isLoading: true,
			isMounted: false,
			analyticsData: null,
			enterCompetition: false,
			redirectUrl:
				"https://www.edgars.co.za/eight-hour-cream-skin-protectant-8501366301?gclid=Cj0KCQjwldKmBhCCARIsAP-0rfx2mNSCYRMq9JjNN05lDblNYSnpmuLcWOiC0gyTSxDY2uUVJT2rmkwaAkobEALw_wcB",

			bottomImages: [
				// {
				// 	uri: require("./Quiz/hp3.jpg"),
				// },
				// {
				// 	uri: require("./Quiz/hp2.jpg"),
				// },
			],
		};
	}

	// async componentDidMount() {
	// 	this.trackInteraction();
	// }

	async componentDidMount() {
		// if (!this.state.isMounted) {
		// 	await this.trackInteractionS();
		// 	await this.checkSignupData();
		// 	await this.setState({ isMounted: true });
		// }
		// const analyticsData = JSON.parse(localStorage.getItem("analyticsData"));
		// if (!analyticsData) {
		// 	localStorage.setItem(
		// 		"analyticsData",
		// 		JSON.stringify(this.state.analyticsData),
		// 	);
		// }
	}

	trackInteractionS = async () => {
		const ip = await this.geoAppify1();

		let analyticsData = JSON.parse(localStorage.getItem("analyticsData"));

		if (!analyticsData) {
			console.log("geoAppify SET", ip);
			let form_id = generateFormId();
			analyticsData = {
				...ip,
				userAgent: window.navigator.userAgent,
				screenWidth: window.screen.width,
				screenHeight: window.screen.height,
				form_id: form_id,
			};
			localStorage.setItem(
				"analyticsData",
				JSON.stringify(analyticsData),
			);

			await fetch(api_qrscanned, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					allocation: "EA - WHITE TEA ACTIVATION - Signup QR",
					...analyticsData,
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					this.setState({
						redirectUrl: data.redirectUrl,
						isLoading: false,
					});
					return null;
				})
				.catch((error) => {
					console.error(error);
					this.setState({ isLoading: false });
				});
		}
	};

	geoAppify1 = async () => {
		try {
			const { data } = await axios.get(`https://ipapi.co/json/`);

			const analyticsData = {
				...data,
				userAgent: window.navigator.userAgent,
				screenWidth: window.screen.width,
				screenHeight: window.screen.height,
			};

			// console.log("geoAppify1", analyticsData);

			return analyticsData;
		} catch (error) {
			return error;
		}
	};

	checkSignupData = () => {
		const signupData = localStorage.getItem("signupData");
		if (signupData) {
			const data = JSON.parse(signupData);
			this.setState({
				enterCompetition: data.enterCompetition,
			});
		}
	};

	redirectTo = () => {
		window.open(
			"https://www.edgars.co.za/eight-hour-cream-skin-protectant-8501366301?gclid=Cj0KCQjwldKmBhCCARIsAP-0rfx2mNSCYRMq9JjNN05lDblNYSnpmuLcWOiC0gyTSxDY2uUVJT2rmkwaAkobEALw_wcB",
			"_blank",
		);
	};

	render() {
		let height = window.innerHeight * 0.5;
		let width = window.innerWidth;

		width = width > 600 ? 600 : width;
		return (
			<View
				style={{
					// flex: 1,
					justifyContent: "center",
					alignItems: "center",
					width: window.innerWidth,
					height: window.innerHeight,
					marginLeft: "auto",
					marginRight: "auto",

					backgroundColor: "#F8F8F8",

					// maxWidth: 600,
				}}>
				{/* <View
					style={{
						position: "absolute",
						alignItems: "center",
						top: 0,
						width: "100%",
						zIndex: 1,
						height: "100%",
					}}>
					<img
						src={require("./Quiz/Background.jpg")}
						alt='Description'
						className='responsive'
					/>
				</View> */}
				{/* <div className='bg-image'></div> */}
				<View
					style={{
						flex: 1,
						zIndex: 99,
						width: width,
					}}>
					<ScrollView showsVerticalScrollIndicator={false}>
						{/* <View
						style={{
							// position: "absolute",
							alignItems: "center",
							width: width,
							zIndex: 99,
							// top: "30%",
							// backgroundColor: "red",
							minHeight: 200,
						}}>
						<img
							src={require("./assets/Splash.webp")}
							alt='Description'
							className='responsive'
						/>
					</View> */}

						{/* <View
							style={{
								width: "100%",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<img
								// src={{
								// 	uri: d.uri,
								// }}
								src={require("./Quiz/hp1.jpg")}
								alt=''
								className='responsive'
							/>
						</View> */}

						{/* <View
							style={{
								position: "absolute",
								alignItems: "center",
								top: "2%",
								left: 0,
								width: width,
								height: 80,
							}}>
							<Image
								source={{
									uri: "https://www.elizabetharden.co.za/images/sitewide/EA-Logo-2020-320x59.svg",
								}}
								style={{
									width: 200,
									height: 90,
									resizeMode: "contain",
								}}
							/>
						</View> */}

						{/* <View
							style={{
								// position: "absolute",
								alignItems: "center",
								// top: "3%",
								// justifyContent: "center",
								height: height,
								width: width,
								// backgroundColor: "red",
								zIndex: 99,
							}}>
							<Fade>
								<Image
									source={require("./Quiz/hp1.jpg")}
									style={{
										top: "0%",
										height: height * 0.8,
										width: width,
										resizeMode: "contain",
									}}
								/>
							</Fade>
						</View> */}
						<View
							style={{
								// height: 30,
								// backgroundColor: "#FFF",
								width: "100%",
								// borderRadius: 15,
								// padding: 5,
								justifyContent: "center",
								alignItems: "center",
								overflow: "hidden",
								paddingBottom: 15,
							}}>
							<View
								style={{
									overflow: "hidden",
									// borderRadius: 15,
								}}>
								<img
									src={require("./EA HydraPlay Quiz.jpg")}
									alt='Description'
									className='responsive'
								/>
							</View>
						</View>

						<View
							style={{
								width: width,
								zIndex: 99,
								flexDirection: "row",
								// marginTop: "10%",
							}}>
							<View
								style={{
									flex: 1,
									padding: 10,
									// height: window.innerHeight * 0.6,
									// maxHeight: 650,
									paddingBottom: 0,
								}}>
								<View
									style={{
										flex: 1,
										// height: 48,
										padding: 10,
										width: "100%",
										borderRadius: 15,
										// alignItems: "center",
										// justifyContent: "center",
									}}>
									{/* <View style={{}}>
									<Text
										style={{
											// fontFamily: "Gotham",
											// fontWeight: "800",
											// fontSize: 16,
											marginBottom: 10,

											fontFamily: "Gotham",
											fontWeight: "600",
											fontSize: 19,
										}}>
										Comic-Con Competition
									</Text>
									<Text
										style={{
											fontFamily: "Gotham",
											fontWeight: "400",
											fontSize: 16,
										}}>
										During the month of September, Purchase
										any Eight Hour® product from
										<Text
											style={{
												fontWeight: "800",
											}}>
											{" "}
											Edgars
										</Text>{" "}
										in-store/online and submit your receipt
										to enter into the draw to{" "}
										<Text
											style={{
												fontWeight: "800",
											}}>
											WIN
										</Text>
										.
									</Text>
									<Text
										style={{
											fontFamily: "Gotham",
											fontWeight: "400",
											fontSize: 16,
										}}>
										One entry per participant
									</Text>
									<TermsAndConditionsCheckBox
										isCheckBoxType={false}
										isAccepted={() => {}}
									/>
								</View> */}

									{/* <View
									style={{
										flexDirection: "row",
									}}>
									<View
										style={{
											width: "90%",
											marginBottom: 20,
										}}>
										<img
											src={require("./assets/edgars_com.png")}
											alt='Edgars logo'
											className='responsive'
										/>
									</View>
								</View> */}

									{/* <View
									style={{
										width: "90%",
										marginBottom: 20,
										maxWidth: 200,
									}}>
									<img
										src={require("./assets/edgars_com.png")}
										alt='Edgars logo'
										className='responsive'
									/>
								</View> */}

									{/* <View
									style={{
										// padding: 10,
										width: "100%",
										alignItems: "flex-start",
										justifyContent: "flex-start",
										marginTop: 10,
										// maxWidth: 300,
									}}>
									<TouchableOpacity
										style={{
											width: "100%",
											alignItems: "flex-start",
											justifyContent: "flex-start",
											maxWidth: 230,
										}}
										onPress={() => {
											this.redirectTo();
										}}>
										<Fade>
											<img
												src={require("./assets/buy_now.png")}
												alt='Description'
												className='responsive'
											/>
										</Fade>
									</TouchableOpacity>
								</View> */}

									{/* <View
									style={{
										flexDirection: "row",
									}}>
									<View
										style={{
											width: "90%",
											marginBottom: 20,
										}}>
										<img
											src={require("./assets/edgars_com.png")}
											alt='Edgars logo'
											className='responsive'
										/>
									</View>
								</View> */}
									{/* <View
									style={{
										// padding: 10,
										width: "100%",
										alignItems: "flex-end",
										justifyContent: "flex-end",
										marginTop: 10,
									}}>
									<TouchableOpacity
										style={{
											width: "100%",
											alignItems: "flex-end",
											justifyContent: "flex-end",
										}}
										onPress={() => {
											window.location.href =
												this.state.redirectUrl;
										}}>
										<View
											style={{
												// height: 48,
												padding: 10,
												width: "100%",

												borderRadius: 10,
												alignItems: "center",
												justifyContent: "center",
												maxWidth: 380,
											}}>
											<Text
												style={{
													fontFamily: "Gotham",
													fontWeight: "400",
													fontSize: 12,
													marginTop: 15,
												}}>
												Terms & conditions apply
											</Text>
										</View>
									</TouchableOpacity>
								</View> */}
								</View>
							</View>
						</View>
						<Fade>
							<View
								style={{
									padding: 10,
									// marginTop: 50,
									alignItems: "center",
									justifyContent: "center",
									paddingTop: 0,
								}}>
								<View
									style={{
										width: "100%",
										justifyContent: "center",
										alignItems: "center",
										marginBottom: 10,
									}}>
									<Text
										style={{
											fontFamily: "Gotham",
											fontWeight: "600",
											fontSize: 25,
											color: "#000",
											// marginTop: 20,
											marginBottom: 15,
										}}>
										20 Winners!
									</Text>
									<Text
										style={{
											fontFamily: "Gotham",
											fontWeight: "400",
											fontSize: 19,
											color: "#000",
											textAlign: "center",
										}}>
										Complete the{" "}
										<strong>Eight Hour® HydraPlay</strong>{" "}
										Quiz & you could win Eight Hour® merch!
									</Text>
								</View>

								<View
									style={{
										// padding: 10,

										width: "100%",
										alignItems: "center",
										justifyContent: "center",
										marginTop: 10,
										// marginBottom: 30,
										maxWidth: 280,
									}}>
									<TouchableOpacity
										style={{
											width: "100%",
										}}
										onPress={() => {
											window.location.href = `${this.props.root}/quiz`;
										}}>
										{/* <View
										style={{
											// height: 48,
											padding: 10,
											width: "100%",
											background: "#4caf50",
											borderRadius: 10,
											alignItems: "center",
											justifyContent: "center",
											// maxWidth: 380,
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontWeight: "800",
												color: "#FFF",
												fontSize: 19,
											}}>
											ENTER!
										</Text>
									</View> */}
										<Fade>
											<img
												src={require("./Button HR.png")}
												alt='Description'
												className='responsive'
											/>
										</Fade>
									</TouchableOpacity>
									<TermsAndConditionsCheckBox
										isCheckBoxType={false}
										isAccepted={() => {}}
									/>
								</View>
							</View>
						</Fade>
						<Fade>
							<View style={{ marginBottom: 50, display: "none" }}>
								<FollowUs {...this.props} />
							</View>
						</Fade>
						{/* <Fade> */}
						{/* <View
							style={{
								// padding: 10,
								width: "100%",
								alignItems: "center",
								justifyContent: "center",
								// marginTop: "5%",
							}}>
							<img
								src={require("./assets/bottomCloud.webp")}
								alt='Description'
								className='responsive'
							/>
						</View> */}

						{/* </Fade> */}
						{/* <View
						style={{
							// padding: 10,
							width: "100%",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<img
							src={require("./assets/4_2.png")}
							alt='Description'
							className='responsive'
						/>
					</View> */}

						{this.state.bottomImages.map((d, i) => {
							return (
								<View
									style={{
										width: "100%",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<img
										// src={{
										// 	uri: d.uri,
										// }}
										src={d.uri}
										alt=''
										className='responsive'
									/>
								</View>
							);
						})}

						{/* <View
						style={{
							// padding: 10,
							width: "100%",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<img
							src={require("./assets/1.png")}
							alt='Description'
							className='responsive'
						/>
					</View>
					<View
						style={{
							// padding: 10,
							width: "100%",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<img
							src={require("./assets/3_2.png")}
							alt='Description'
							className='responsive'
						/>
					</View>
					<View
						style={{
							// padding: 10,
							width: "100%",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<img
							src={require("./assets/EA_1H23_8 HOUR_SUPER POWER_RESUPPORT_SOCIAL_ASSET_5_9X16.jpg")}
							alt='Description'
							className='responsive'
						/>
					</View> */}
					</ScrollView>
				</View>
			</View>
		);
	}

	// lastChanceModal = () => {
	// 	let screenHeight = window.innerHeight;
	// 	let screenWidth = window.innerWidth;

	// 	screenWidth = screenWidth >= 500 ? 500 : screenWidth;
	// 	return (
	// 		<Modal
	// 			animationType='fade'
	// 			transparent={true}
	// 			visible={applyNow}
	// 			onRequestClose={() => {}}>
	// 			<View
	// 				style={{
	// 					flex: 1,
	// 					position: "absolute",
	// 					zIndex: 999,
	// 					backgroundColor: "rgba(52, 52, 52, 0.8)",
	// 					width: window.innerWidth,
	// 					height: window.innerHeight,
	// 				}}>
	// 				<View
	// 					style={{
	// 						flex: 1,
	// 						justifyContent: "center",
	// 						alignItems: "center",
	// 						padding: 10,
	// 					}}>
	// 					<View
	// 						style={{
	// 							width: "100%",
	// 							height: screenHeight - 120,
	// 							backgroundColor: "#FFF",
	// 							borderRadius: 20,
	// 							// padding: 10,
	// 							justifyContent: "center",
	// 							alignItems: "center",
	// 							shadowColor: "#999797",
	// 							shadowOffset: {
	// 								width: 0,
	// 								height: 4,
	// 							},
	// 							shadowOpacity: 0.3,
	// 							shadowRadius: 4.65,
	// 							elevation: 8,
	// 						}}>
	// 						<View
	// 							style={{
	// 								alignItems: "center",
	// 								width: width,
	// 								minHeight: 200,
	// 							}}>
	// 							<img
	// 								src={require("./assets/Splash.webp")}
	// 								alt='Description'
	// 								className='responsive'
	// 							/>
	// 						</View>
	// 					</View>
	// 				</View>
	// 			</View>
	// 		</Modal>
	// 	);
	// };
}
