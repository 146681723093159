import React, { Component } from "react";
import { View, Text, Image, ScrollView } from "react-native";

const first = require("../ActivationLeaderboard/1.png");
const second = require("../ActivationLeaderboard/2.png");
const third = require("../ActivationLeaderboard/3.png");

export default class Leaderboard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let { leaderboard } = this.props;
		return (
			<View
				style={{
					flex: 1,
					// justifyContent: "center",
					alignItems: "center",
				}}>
				<View
					style={{
						// flex: 1,
						width: "100%",
						// padding: 10,
						paddingTop: 10,
						maxWidth: 500,
						height: window.innerHeight - 210,
						// height: 300,
					}}>
					<ScrollView>
						{leaderboard
							.filter((d_) => d_.score > 0)
							.sort(
								(a, b) => parseInt(b.score) - parseInt(a.score),
							)

							.map((entry, index) => this.item(entry, index))}
					</ScrollView>
				</View>
			</View>
		);
	}

	item = (entry, index) => {
		let img = index === 0 ? first : index === 1 ? second : third;
		return (
			<View
				key={index}
				style={{
					flexDirection: "row",
					backgroundColor: "#FFF",
					padding: 5,
					borderRadius: 10,
					marginBottom: index < 3 ? 2 : 1,
					width: "100%",
					alignItems: "center",
					paddingVertical: 8,
					opacity: index < 3 ? 1 : 0.9,
				}}>
				{index < 3 ? (
					<Image
						source={img}
						style={{
							width: 45,
							height: 45,
							resizeMode: "contain",
						}}
					/>
				) : (
					<Text
						style={{
							fontSize: 19,
							width: 35,
							textAlign: "center",
							fontWeight: index < 3 ? "800" : "300",
							// opacity: index < 3 ? 1 : 0.3,
						}}>
						{index + 1}
					</Text>
				)}

				<Text
					style={{
						fontSize: index < 3 ? 22 : 19,
						flex: 1,
						fontWeight: index < 3 ? "600" : "300",
						paddingLeft: 5,
					}}>
					{entry.user_data.firstName.slice(0, 1).toLocaleUpperCase()}
					{entry.user_data.firstName.slice(1, 15)}
				</Text>
				<Text
					style={{
						fontSize: 12,
						// flex: 1,
					}}>
					{entry.mall.slice(0, 1).toLocaleUpperCase()}
					{entry.mall.slice(1, 15)}
				</Text>
				{/* <Text
					style={{
						fontSize: 12,
						fontWeight: "300",
						opacity: 0.3,
					}}>
					{entry.uniqueCode}
				</Text> */}
				<Text
					style={{
						fontSize: 19,
						width: 80,
						fontWeight: index < 3 ? "800" : "300",
						textAlign: "right",
					}}>
					{entry.score}
				</Text>
			</View>
		);
	};
}
