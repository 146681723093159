// App.js
import React, { useState } from "react";

import { View, Text, Image, TouchableOpacity } from "react-native";
import "./Quiz.css";
import Signups from "../Signup/EaSignups";

function Quiz({ root }) {
	const questions = [
		{
			question:
				"How many great benefits does the NEW Eight Hour® HydraPlay™ Skin Perfecting Daily Moisturizer offer?",
			options: ["3", "5", "8"],
			correctAnswer: "5",
		},
		{
			question:
				"Which great benefit of the NEW Eight Hour® HydraPlay™ Skin Perfecting Daily Moisturizer are you most excited about?",
			options: [
				"💧 Hydrate",
				"💡 Brighten",
				"💎 Perfect & Refine Pores",
				"👑 Prime",
				"〰️ Smooth",
				"🍃 Soothe",
				"🏋️‍♀️ Strengthen Barrier",
				"🛡️ Oil Control",
			],
			correctAnswer: null, // Open-ended, no score calculation
		},
		{
			question:
				"What % of active ingredients of the NEW HydraPlay™ Skin Perfecting Daily Moisturizer are naturally-derived?",
			options: ["85%", "87%", "95%"],
			correctAnswer: "95%",
		},
		{
			question:
				"The NEW HydraPlay™ Skin Perfecting Daily Moisturizer is vegan.",
			options: ["True", "False"],
			correctAnswer: "True",
		},
		{
			question:
				"The NEW HydraPlay™ Skin Perfecting Daily Moisturizer can be used...",
			options: ["In the morning", "At night", "Morning and night"],
			correctAnswer: "Morning and night",
		},
		{
			question:
				"Which of these Eight Hour® products is most iconic to you?",
			options: [
				"The Original Eight Hour® Cream Skin Protectant",
				"The NEW HydraPlay™ Skin Perfecting Daily Moisturizer",
				"The Eight Hour® Cream Lip Protectant Stick Sunscreen SPF15",
			],
			correctAnswer: null, // Open-ended, no score calculation
		},
	];

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [score, setScore] = useState(0);
	const [details, setDetails] = useState({ name: "", email: "" });
	const [quizFinished, setQuizFinished] = useState(false);

	let [answers, setanswers] = useState([]);

	const handleAnswerOptionClick = (slected, isCorrect) => {
		if (isCorrect) {
			setScore(score + 1);
		}
		answers.push(slected);
		console.log(answers);
		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setQuizFinished(true);

			// window.location.href = `${root}/signup`;
			localStorage.setItem("quizResults", JSON.stringify(answers));
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setDetails({ ...details, [name]: value });
	};

	return (
		<View
			style={{
				// flex: 1,
				justifyContent: "center",
				alignItems: "center",
				width: window.innerWidth,
				height: window.innerHeight,
				marginLeft: "auto",
				marginRight: "auto",

				backgroundColor: "#F8F8F8",

				// maxWidth: 600,
			}}>
			{/* <div className='bg-image'></div> */}

			<div className='app'>
				{quizFinished ? (
					<div className='question-section'>
						<Signups root={root} />
					</div>
				) : (
					<>
						<TouchableOpacity
							onPress={() => {
								window.location.href = "/";
							}}>
							<View
								style={{
									width: 36,
									height: 36,
									justifyContent: "center",
									alignItems: "center",
									borderRadius: 12,
									marginTop: 10,
									backgroundColor: "#E8E8E8",
									marginLeft: 10,
								}}>
								<Image
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
									source={require("./back.png")}
								/>
							</View>
						</TouchableOpacity>
						<div className='question-section'>
							<h2>Question {currentQuestion + 1}</h2>
							<div className='question-text'>
								{questions[currentQuestion].question}
							</div>
						</div>
						<div className='answer-section'>
							<View
								style={{
									// padding: 10,
									width: "100%",
									alignItems: "center",
								}}>
								{questions[currentQuestion].options.map(
									(option, index) => (
										<TouchableOpacity
											key={index}
											style={{
												// backgroundColor: "red",
												marginBottom: 35,

												borderRadius: 10,
												// padding: 10,
												width: 320,
												height: 75,
												justifyContent: "center",
												alignItems: "center",
											}}
											onPress={() => {
												handleAnswerOptionClick(
													option,
													option ===
														questions[
															currentQuestion
														].correctAnswer,
												);
											}}>
											<View
												style={{
													position: "absolute",
													width: 320,
													height: 62,
													top: 0,
												}}>
												<img
													src={require("../Blank button.png")}
													alt='Description'
													className='responsive'
												/>
											</View>
											<Text
												style={{
													color: "#FFF",
													fontSize: 18,
													// position: "absolute",
													zIndex: 99,
												}}>
												{option}
											</Text>
										</TouchableOpacity>
									),
								)}
							</View>
						</div>
					</>
				)}
			</div>
		</View>
	);
}

export default Quiz;
